import { get } from "lodash"
import { Form } from "antd"
import React from "react"
import { AntdFormItemProps } from "../RegisterModal/types"

export const AntdFormItem = <M,>(props: AntdFormItemProps<M>) => {
  const { className, children, touched, errors, name, label, labelCol, wrapperCol } = props

  const error = get(errors, name)
  const touch = get(touched, name)

  const renderErrorMessages = (error: unknown) => {
    if (typeof error === "string") {
      return error
    } else if (typeof error === "object" && error !== null) {
      return Object.values(error).join(" ")
    }
    return ""
  }

  return (
    <Form.Item
      {...props}
      className={className}
      name={name}
      label={label}
      labelCol={
        labelCol || {
          lg: { span: 9 }
        }
      }
      wrapperCol={
        wrapperCol || {
          lg: { span: 15 }
        }
      }
      help={touch && error ? renderErrorMessages(error) : ""}
      validateStatus={touch && error ? "error" : "success"}>
      {children}
    </Form.Item>
  )
}
